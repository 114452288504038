export default {
  // ? =============================
  // ? =======  reuseables  ========
  // ? =============================

  title: {
    fontSize: ['2rem', '', '', '2.5rem'],
    order: '2',
    textTransform: 'uppercase',
    padding: '1rem 0rem',
    width: 'fit-content'
  },
  subtitle: {
    fontWeight: '300',
    opacity: '0.8',
    margin: '0rem 0rem 1.5rem',
    order: '1'
  },

  text: {
    order: '3',
    fontSize: '1rem',
    lineHeight: '2',
    color: 'inherit',
    '*': {
      color: 'inherit'
    },
    em: {
      fontFamily: 'handwriting',
      fontSize: '2em',
      letterSpacing: '2px'
    }
  },

  ctaButton: {
    order: '4',
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  opaqueBackground: {
    content: "''",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgb(193 207 195 / 46%)'
  },

  // ? ==========================
  // ? =======  Layout  =========
  // ? ==========================

  navigation: {
    '> .container': {
      padding: ['0.5rem', '0.5rem', '0.5rem', '4.5rem 1rem'],
      '.smallNavMenu': {
        '.react-reveal': {
          display: 'flex',
          flexDirection: 'row'
        },
        '.navItemDropdownMenu': {
          background: 'transparent'
        }
      }
    },
    '.containerScrolled': {
      backgroundColor: 'backgroundSecondary',
      '.logoLocationContainer': {
        // display: 'none',
        margin: ['', '', '', 'auto auto auto 1rem'],
        position: ['', '', '', 'static'],
        padding: '0rem',
        '.container': {
          // padding: ['0.5rem'],
        },
        '.logoScrolled ': {
          maxWidth: '150px',
          marginBottom: '0rem',
          maxHeight: 'unset',

          display: 'inline',
          padding: '0rem',
          a: {
            display: 'flex'
          },
          img: {
            maxHeight: 'unset',
            maxWidth: '150px',
            height: 'unset',
            padding: '0rem'
          }
        },
        '.locationSwitcher': {
          justifyContent: 'center',
          button: {
            fontSize: '0.8rem'
          }
        },
        '.popUp': {
          transform: 'unset'
        }
      }
    },
    '.logoLocationContainer': {
      // display: 'none',
      margin: ['', '', '', '0 auto 0 0'],
      position: ['', '', '', 'static'],
      '.container': {
        // padding: ['0.5rem'],
      },
      '.logo': {
        maxWidth: '150px',
        marginBottom: '0.5rem',
        img: {
          filter: 'unset'
        }
      },
      '.locationSwitcher': {
        justifyContent: 'center',
        button: {
          fontSize: '0.8rem'
        }
      },
      '.popUp': {
        transform: 'unset'
      }
    },

    '.smallNavMenu': {
      '.react-reveal': { display: 'flex', flexDirection: 'row' },
      '.navItemDropdownMenu': {
        background: 'black',
        width: 'fit-content'
      },
      '.navItem': {
        margin: ['', '', '', '0.75rem', '1rem'],
        a: {
          fontSize: '1rem',
          color: 'inherit',
          ':hover': {
            color: 'lightgrey',
            textDecoration: 'underline'
          }
        }
      },
      '.reservations': {
        // margin: '0rem',
        a: {
          padding: '1rem',
          border: 'solid 1px',
          borderColor: 'primary'
        }
      }
    },
    '.hamburger': {
      backgroundColor: 'rgb(0 0 0 / 42%)',
      div: {
        backgroundColor: 'white'
      }
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundColor: 'backgroundSecondary',
      width: ['85%', '60%', '50%', '40%'],
      color: 'light',
      '.navItemDropdownMenu': {
        position: 'static',
        background: 'transparent'
      },
      '.navItem': {
        textAlign: 'right',
        a: {
          color: 'dark',
          fontSize: '1.2rem'
        }
      },
      '.navMenuLogo': {
        position: ['', '', '', ''],
        width: ['', '', '', '100%'],
        maxWidth: '300px',
        left: '1rem',
        margin: '0rem 0.5rem 2rem',
        img: {
          maxHeight: ['', '', '', 'unset'],
          maxWidth: ['', '', '', 'unset'],
          width: ['', '', '', '100%']
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          color: 'dark'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right']
      },

      '.socialIconsContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        svg: {
          filter: 'brightness(0)'
        },
        '.socialContainer': {
          flexDirection: 'row'
        },
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      }
    },

    '.navBlockOpen': {
      width: ['15%', '40%', '50%', '60%'],
      left: '0rem',
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }
  },

  footer: {
    padding: ['0rem', '', '', '0rem'],
    backgroundColor: 'background',
    borderTop: '#fafafa 2px solid',
    color: 'dark',
    display: 'flex',
    flexDirection: 'column',
    '::after': {
      content: `"© ${new Date().getFullYear()} Tagliare"`,
      width: '100%',
      backgroundColor: 'background',
      color: 'dark',
      padding: '1rem 0rem',
      marginBottom: ['40px', '', '', '0px'],
      textTransform: 'uppercase',
      order: '6',
      textAlign: 'center',
      fontFamily: 'heading'
    },
    '.logo': {
      maxWidth: '200px'
      // filter: 'brightness(0) invert(1)',
    },
    '.contactDetails-container': {
      margin: '2rem 0rem'
    },
    '.sectionHeading': {
      borderBottom: '0rem',
      fontSize: '1.5rem',
      fontFamily: 'body',
      textTransform: 'uppercase',
      marginBottom: ['0rem', '', '', '1rem']
    },
    '.column': {
      color: 'dark',
      marginBottom: '0rem',
      padding: '3rem 2rem',
      width: ['', '', '', '20%'],
      flexGrow: '1',
      maxWidth: ['unset', '', '', '350px']
    },
    '.contactColumn': {
      borderRight: 'none !important',
      padding: '3rem 2rem !important'
    },
    '.exploreColumn': {
      order: ['5', '', '', '5'],
      backgroundColor: 'background',
      margin: ['', '', '', '0 0 0 auto'],
      marginBottom: '0rem',
      padding: ['', '', '4rem 3rem', '5rem 4rem'],
      width: ['100%', '', '', '40%'],
      '.sectionHeading': {
        color: 'dark',
        fontSize: '2rem',
        textAlign: 'left',
        width: '100%',
        padding: ['1.25rem', '', '', '0rem'],
        fontWeight: '400'
      },

      '.multiButtonContainer': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        a: {
          width: '100%',
          color: 'dark',
          fontSize: ['1rem', '', '1.25rem'],
          letterSpacing: '1px',
          fontWeight: '600',
          padding: '0.25rem 0rem'
        }
      }
    },
    '.gonationLogo': {
      filter: 'brightness(0)'
    },
    '.copyright': {
      display: 'none'
    },

    '.socialContainer svg path': {
      fill: 'dark'
    },
    '.footerAboutButton span': {
      variant: 'buttons.primary',
      fontSize: '0.9rem',
      padding: '0.75rem 1.5rem'
    },
    '.button': {
      variant: 'buttons.primary',
      borderRadius: '500px',
      padding: '0.75rem 1.5rem'
    },
    '.aboutText': {
      color: 'dark'
    },
    '.popUp': {
      '.content': {
        padding: '3rem 1rem',
        '.title': {
          fontSize: '1.5rem',
          opacity: '0.7'
        }
      },
      '.locations': {
        a: {
          variant: 'buttons.primary'
        }
      }
    }
  },

  sideBySide1: {
    '.section': {
      padding: ['1rem', '', '3rem', '4rem', '5rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ? =======================
  // ? =====  Homepage  ======
  // ? =======================

  homepageHero: {
    '.hero_content_container': {
      margin: 'auto',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      zIndex: '1',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%'
    },
    '.logoHero': {
      maxHeight: '180px',
      display: 'none'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'light'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },

    '.slick-slider': {
      '::after': {
        // variant: 'customVariants.opaqueBackground'
      },
      '.slick-dots': {
        'li button:before': {
          fontSize: '20px'
        }
      }
    }
  },
  homepageShout: {},

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    paddingBottom: '3rem',
    padding: '0',
    'div.section': {
      m: '0rem auto 0rem 0rem',
      alignItems: 'flex-start',
      textAlign: 'left',
      maxWidth: 'unset',
      width: '100%',
      backgroundImage: "url('https://res.cloudinary.com/gonation/image/upload/v1683210146/sites/tagliare/Wave_2.svg')",
      paddingTop: '4rem',
      backgroundSize: 'cover'
    },
    'h2.title': {
      fontFamily: 'display',
      textTransform: 'unset',
      fontSize: ['3rem', '4rem', '5rem', '6rem', '7rem', '8rem'],
      fontWeight: 'lighter',
      textAlign: 'center',
      width: '100%',
      marginBottom: '0'
    },
    'h2.subtitle': {
      textTransform: 'uppercase',
      textAlign: 'center',
      borderBottom: 'solid 3px',
      borderColor: 'secondary',
      color: 'secondary'
    },
    'div.text': {
      maxWidth: '767px',
      margin: '0 auto',
      textAlign: 'center',
      '*': {
        fontSize: ['1rem', '1.1rem', '1.2rem', '1.3rem']
      }
    },
    'a.ctaButton': {
      borderRadius: '0px',
      margin: '0 auto',
      // backgroundColor: 'dark',
      color: 'light',
      border: 'none',
      border: 'solid 2px',
      borderColor: 'secondary',
      transition: 'all ease-in-out 0.7s',
      backgroundSize: '100% 204%',
      backgroundImage: 'linear-gradient(to bottom,#b6a58f 50%, #FCD8D4 50%)!important',
      padding: '.75rem 1.5rem',
      fontFamily: 'heading',
      fontWeight: 'normal',
      fontSize: ['1.25rem', '1.5rem', '1.5rem', '2rem'],
      ':hover': {
        backgroundColor: 'transparent',
        backgroundPosition: '0% -98%',
        color: 'light',
        borderColor: 'black'
      }
    }
    // background: 'url(https://www.transparenttextures.com/patterns/arches.png)'
  },

  imageBoxes: {
    '::before': {
      content: "'See Our Range Of Services'",
      position: 'absolute',
      top: '0',
      left: '0',
      textAlign: 'center',
      width: '100%',
      height: '30px',
      fontSize: ['3rem', '4rem', '5rem', '6rem', '7rem', '8rem'],
      fontFamily: 'display'
    },
    padding: '1rem',
    paddingTop: '8rem',
    position: 'relative',
    margin: '8rem 0',

    '.box': {
      padding: '0rem',
      width: ['100%', '', 'calc(50% - 2rem)', 'calc(33% - 2rem)'],
      position: 'relative',
      height: '400px',
      background: 'transparent',
      color: 'white',
      margin: '0rem',
      borderRadius: '0px',
      margin: '1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      ':hover': {
        '.image': {
          filter: 'brightness(0.75)'
        }
      }
    },
    '.image': {
      position: 'absolute',
      height: '100%',
      zIndex: '-1',
      objectFit: 'cover',
      marginBottom: '0rem',
      boxShadow: '2px 2px 10px grey',
      transition: 'all ease-in-out 0.7s'
    },
    '.title': {
      padding: '1rem 2rem',
      order: '2',
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '1.75rem', '2rem', '', '2.25rem']
    },
    '.subtitle': {
      margin: '3rem 2rem 1rem',
      padding: '1rem 0rem',
      borderBottom: '2px solid white',
      width: 'calc(100% - 4rem)',
      textTransform: 'uppercase',
      order: '1',
      fontWeight: '400'
    },
    '.text': {
      padding: '0rem 2rem',
      order: '3',
      color: 'white',
      margin: '1rem 0rem 2rem'
    },
    '.ctaLink': {
      margin: '0rem 2rem 3rem',
      order: '4',
      border: 'solid 2px white',
      backgroundColor: 'transparent',
      width: 'fit-content',
      borderRadius: '0px',
      textTransform: 'uppercase',
      padding: '0.5rem 2rem',
      fontSize: '0.9rem',
      fontWeight: 'bold',
      ':hover': {
        backgroundColor: 'primary',
        borderColor: 'primary'
      }
    }
  },

  homepageStylists: {
    variant: 'customVariants.sideBySide1',
    color: 'white',
    'div.section': {
      maxWidth: '1000px'
    },
    padding: '0',
    'div.text': {
      '*': {
        color: 'white',
        fontSize: ['1.5rem', '', '2rem']
      }
    }
  },
  homepageGallery: {
    variant: 'customVariants.sideBySide1',
    padding: '3rem',
    height: 'calc(60vh + 6rem)',
    minHeight: 'unset',
    '.slick-initialized': {
      // clipPath: 'polygon(40% 0%, 100% 0%, 100% 100%, 0% 100%)',
      '.slick-slide > div': {
        height: '60vh'
      }
    },
    '.slick-slide img': {
      height: '60vh'
    },
    '.slick-arrow': {
      display: 'none!important'
    },
    '.hero_content_container': {
      top: '1rem',
      transform: 'unset',
      '.title, .subtitle': {
        fontFamily: 'display',
        textTransform: 'unset',
        fontSize: ['2.5rem', '3rem', '4rem', '5rem', '6rem', '8rem'],
        fontWeight: 'lighter',
        textAlign: 'left'
      },
      '.subtitle': {
        order: '2'
      }
    }
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide1',
    padding: '0',
    // paddingBottom: '1rem',
    'div.section': {
      m: '0rem auto 0rem 0rem',
      alignItems: 'flex-start',
      textAlign: 'left',
      maxWidth: 'unset',
      width: '100%',
      backgroundColor: 'background'
    },
    'h2.title': {
      fontFamily: 'display',
      textTransform: 'unset',
      fontSize: ['3rem', '4rem', '5rem', '6rem', '7rem', '8rem'],
      fontWeight: 'lighter',
      textAlign: 'left',
      lineHeight: 1,
      mb: '0rem'
    },
    'h2.subtitle': {
      textTransform: 'uppercase',
      textAlign: 'left',
      borderBottom: 'solid 3px',
      borderColor: 'secondary',
      color: 'secondary'
    },
    'div.text': {
      maxWidth: '767px',
      '*': {
        fontSize: ['1rem', '1.1rem', '1.2rem', '1.3rem']
      }
    },
    'a.ctaButton': {
      borderRadius: '0px',
      backgroundColor: 'dark',
      color: 'light',
      border: 'none',
      border: 'solid 2px',
      borderColor: 'secondary',
      transition: 'all ease-in-out 0.7s',
      backgroundSize: '100% 204%',
      backgroundImage: 'linear-gradient(to bottom,#b6a58f 50%, #FCD8D4 50%)!important',
      padding: '1rem 2rem',
      fontFamily: 'heading',
      fontWeight: 'normal',
      fontSize: ['1.25rem', '1.5rem', '1.5rem', '2rem'],
      ':hover': {
        backgroundColor: 'transparent',
        backgroundPosition: '0% -98%',
        color: 'light',
        borderColor: 'black'
      }
    }
  },
  homepageEventsTwo: {
    flexWrap: ['wrap', 'wrap', 'nowrap', 'nowrap'],
    minHeight: ['', '', '90vh'],
    '.lazyload-wrapper': {
      width: '100%',
      clipPath: 'polygon(20% 0, 100% 0, 100% 100%, 0% 100%)',
      img: {
        maxHeight: '1000px'
      }
    },
    '.content': {
      padding: ['2rem', '2rem', '3rem', '4rem', '5rem'],
      alignItems: 'center',
      textAlign: 'center',
      position: 'relative',
      flexDirection: 'column',
      justifyContent: ['initial', 'initial', 'center', 'center'],
      '::before': {
        content: "''",
        width: '40px',
        height: '1px',
        backgroundColor: 'primary',
        position: 'absolute',
        left: '0.5rem',
        top: '50%'
      },
      '::after': {
        content: "''",
        width: '40px',
        height: '1px',
        backgroundColor: 'primary',
        position: 'absolute',
        right: '0.5rem',
        top: '50%'
      }
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      padding: '1rem'
    },
    a: {
      variant: 'customVariants.ctaButton'
    }
  },

  // clip-path: polygon(0% 0,80% 0,100% 100%,0% 100%);

  homepageImages: {
    padding: '3rem 1rem',
    '.textContent': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      p: '2rem 2rem 0rem'
    },

    '.slick-list': {
      height: ['50vh', '', '', '50vh'],
      '.slick-track': {
        height: ['50vh', '', '', '50vh'],
        '.slick-slide': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '1rem',
          '> div': {
            height: '100%',
            width: '100%',
            img: {
              objectFit: 'cover',
              height: '100%',
              width: '100%',
              objectPosition: 'center center'
            }
          }
        }
      }
    }
  },
  homepageSlider: {
    padding: '0rem',
    '> div': {
      padding: '0.5rem 0px'
    },
    '.slick-slide img': {
      margin: '0rem 0.75rem'
    },
    '.slick-dots': {
      display: 'none !important'
    },
    '.mainTextContainer': {
      display: 'none'
    }
  },

  homepageContact: {
    variant: 'customVariants.sideBySide1',
    minHeight: '65vh',
    // background: 'url(https://www.transparenttextures.com/patterns/arches.png)',
    background: 'url(https://www.transparenttextures.com/patterns/brick-wall-dark.png)',
    // color: 'white',
    backgroundColor: '#92b69714',
    '.section': {
      backgroundColor: 'transparent',
      p: 3,
      height: '100%',
      margin: 'auto'
    }
  },

  //  ? ======================
  //  ? ====  About page  ====
  //  ? ======================

  aboutSection: {
    '.title': {
      fontSize: '3.5rem',
      lineHeight: '1',
      color: 'primary'
    }
  },

  aboutPageSlider: {
    padding: '0rem',
    '.slick-dots': {
      display: 'none !important'
    }
  },

  //  ? ========================
  //  ? ======  Menu page  =====
  //  ? ========================
  menu: {
    '.menuItemContainer': {
      width: ['100%', '100%', '100%', '100%', '100%'],
      margin: '0rem'
    },
    '.menuItemNamePriceContainer': {
      display: ['', '', 'flex']
    },
    '.menuItemName': {
      mr: 'auto',
      mb: ['', '', '0rem'],
      display: 'flex',
      alignItems: 'center',
      fontSize: ['1.5rem']
    },
    '.itemVariantsContainer': {
      display: ['', '', 'flex']
    },
    '.variantContainer': {
      flexDirection: ['', '', 'column'],
      p: ['', '', '1rem'],
      width: ['', '', '165px']
    },
    '.menuItemPrice': {
      p: ['', '', '1.1rem']
    },
    '.menuSectionTitle': {
      margin: '1rem 0rem 1rem',
      borderBottom: '2px solid black',
      padding: '0 1rem 1rem',
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '', '2rem'],
      textAlign: 'left',
      color: 'secondary'
    },
    '.menuSectionDescription': {
      margin: '0rem auto 1.5rem',
      fontSize: '1.1rem',
      maxWidth: '800px'
    },
    '.menuItemPriceLabel': {
      textAlign: 'right',
      mr: ['auto', '', '0rem'],
      mb: ['', '', '0.5rem'],
      fontWeight: 'bold',
      fontFamily: 'heading'
    },
    '.menuItemPriceVariants': {
      textAlign: 'right'
    }
  },

  // menu: {
  //   // border: 'solid 1px black',
  //   padding: ['1rem', '1rem', '1rem', '1rem'],
  //   margin: ['1rem 0.5rem', '', '', '', '1rem auto 3rem'],
  //   width: ['calc(100% - 1rem)'],
  //   '.backToMenuBtn': {
  //     mb: '2rem'
  //   },
  //   backgroundColor: 'transparent',
  //   '.section-cell': {
  //     flexGrow: '1',
  //     position: 'relative',
  //     color: 'white',
  //     borderRadius: '0px'
  //   },
  //   '.menuContainer': {
  //     paddingBottom: '1rem'
  //   },
  //   '.tabsRow': {
  //     marginBottom: '2rem',
  //     display: 'none'
  //   },
  //   '.dropdownContainer': {
  //     display: 'none'
  //   },
  //   '#menuTab': {
  //     flexGrow: '1',
  //     width: 'fit-content',
  //     flexGrow: '1',
  //     display: 'flex',
  //     maxWidth: 'unset',
  //     minWidth: 'unset',
  //     textAlign: 'center',
  //     fontWeight: 'bold',
  //     justifyContent: 'center',
  //     ':hover': {
  //       backgroundColor: 'primary',
  //       opacity: '0.7'
  //     }
  //   },
  //   '.menuItemInnerContainer, .menuItemContentContainer': {
  //     margin: '0rem'
  //   },
  //   '.menuSectionTitle': {
  //     margin: '1rem 0rem 1rem',
  //     borderBottom: '2px solid black',
  //     padding: '0 1rem 1rem',
  //     textTransform: 'uppercase',
  //     fontSize: ['1.5rem', '', '2rem'],
  //     textAlign: 'left',
  //     color: 'secondary'
  //   },
  //   '.menuSectionDescription': {
  //     margin: '0rem auto 1.5rem',
  //     fontSize: '1.1rem',
  //     maxWidth: '800px'
  //   },
  //   '.menuItemDescription': {
  //     // color: 'primary',
  //   },
  //   // '.menuPriceContainer': {
  //   //   display: 'none',
  //   // },
  //   // '.variantsContainer': {
  //   //   display: 'none',
  //   // },

  //   '.menuCell': {
  //     border: 'none'
  //   },
  //   '.cellName': {
  //     position: 'absolute',
  //     top: '50%',
  //     left: '50%',
  //     transform: 'translate(-50%,-50%)',
  //     fontFamily: 'handwriting',
  //     textTransform: 'lowercase',
  //     fontSize: '3.5rem'
  //   },
  //   '.cellImage': {
  //     filter: 'brightness(0.6)',
  //     borderRadius: '0px'
  //   },

  //   '.menuItemContainer': {
  //     padding: '0.5rem'
  //     // margin: '0.5rem',
  //   },
  //   '.menuItemName': {
  //     textTransform: 'uppercase',
  //     fontFamily: 'heading',
  //     letterSpacing: '2px',
  //     fontSize: '1.25rem'
  //   },
  //   '.menuPriceContainer': {
  //     minWidth: '50px'
  //   }
  // },

  menuCTAS: {
    '.ctaLink': {
      variant: 'buttons.primary',
      borderRadius: '0px',
      margin: '0 auto'
    }
  },

  //  ? ========================
  //  ? ====  Events page  ====
  //  ? ========================

  events: {
    '.logo': {
      filter: 'brightness(0)'
    },
    '.eventItemImage': {
      minHeight: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    }
  },

  //  ? ========================
  //  ? ====  Gallery page  ====
  //  ? ========================

  gallery: {
    // '.albumsContainer': {
    //   display: 'none',
    // },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black'
    }
  },

  //  ? ========================
  //  ? ====  Contact page  ====
  //  ? ========================

  contactForm: {
    padding: '6rem 1rem',
    order: '4',
    backgroundColor: '#f0f0ee',
    '.title': {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
      padding: '0.5rem',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1.25rem',
      opacity: '0.7',
      fontWeight: '200',
      color: 'black',
      marginBottom: '2rem'
    },
    '.text': {},
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid black',
        margin: '1.5rem 0rem'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid black',
        margin: '1.5rem 0rem'
      },
      '.submitBtn': {
        borderRadius: '0px'
      }
    }
  },

  locationMap: {
    order: '3',
    backgroundColor: '#e4e4e4',
    '.content_container': {
      padding: '4rem 1rem'
    },
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
      padding: '0.5rem'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },
  contactBooking: {
    order: '4',
    variant: 'customVariants.sideBySide1',
    '.title': {
      mb: '0rem'
    }
  },

  // ? ============
  // ? === Team ===
  // ? ============

  teamMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],

    '.boxesHeading': {
      display: 'none'
    },
    '.box': {
      maxWidth: ['', '50%', '30%']
    },
    '.box .juliette-galeas': {
      display: 'none'
    },
    '.imageContainer': {
      img: {
        objectPosition: 'top'
      }
    }
  },
  teamPage: {
    '::before': {
      // this gives the nav a background without having a hero.
      content: ['', '', '', "''"],
      display: 'flex',
      width: '100%',
      height: ['100px', '', '', '125px'],
      mb: '1rem',
      backgroundColor: 'background'
    },
    '.imageContainer': {
      width: ['100%', '', '50%']
    },
    '.contentRow': {
      padding: '2rem',
      width: ['100%', '', '50%'],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    maxWidth: '1200px',
    m: 'auto'
  }
}
